<template>
  <div>
    <!--表格-->
    <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
      :columns="tableColumns" :data="tableData" stripe border></Table>

    <!-- 分页 -->
    <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
      @on-page-size-change="handlePageSize" style="float: right;" />
  </div>
</template>

<script>
import tableHeightMixin from '_m/tableHeight.mixin'
import tablePageMixin from '_m/tablePage.mixin'

export default {
  name: 'Merchant',
  mixins: [tableHeightMixin, tablePageMixin],
  data () {
    return {
      tableData: [],
      currentMerchantItem: null,
    }
  },
  created () {
    this.initData()
  },
  computed: {
    tableColumns () {
      let columns = []
      columns.push({
        title: '酷点商户id',
        tree: true,
        key: 'kdMchId',
        align: 'center',
      })
      columns.push({
        title: '支付类型',
        key: 'type',
        align: 'center',
          render: (h, params) => {
            const state = params.row.type
            let a = '其它'
            if (state === 0) {
                a = '微信'
            } else if (state === 1) {
              a = '支付宝'
            } else if (state === 2) {
             a = '酷点支付'
            }
            return h('div', {
            }, a)
          }
      })
      columns.push({
        title: '状态',
        key: 'status',
        align: 'center',
      render: (h, params) => {
        const state = params.row.status
        let a = '正常'
        if (state !== 0) {
            a = '禁用'
        }
        return h('div', {
        }, a)
      }
      })
      return columns
    }
  },
  methods: {
    initData () {
      this.loading = true
      this.$http.post(`/portal/v1/pay-account/m/findListByPage?page=${this.currentPage}&pageCount=${this.pageSize}`, {
          status: 0
      }).then((response) => {
        const { code, data } = response
        if (code === 1) {
            this.tableData = data.records
            this.total = data.total
        } else {
          this.$Notice.error({
            title: `接口报错:code=${code}`,
            desc: msg
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSuccess () {
      this.initData()
    }
  },
}
</script>
