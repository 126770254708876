var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          "row-key": "id",
          "tooltip-theme": _vm.$store.state.theme,
          height: _vm.tableHeight,
          loading: _vm.loading,
          columns: _vm.tableColumns,
          data: _vm.tableData,
          stripe: "",
          border: ""
        }
      }),
      _c("Page", {
        ref: "footer",
        staticStyle: { float: "right" },
        attrs: {
          total: _vm.total,
          current: _vm.currentPage,
          "show-total": "",
          "show-sizer": ""
        },
        on: {
          "on-change": _vm.handleCurrentChange,
          "on-page-size-change": _vm.handlePageSize
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }